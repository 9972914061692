<template>
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择（上下架）"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <!-- <el-form-item>
            <el-cascader class="width-all"
                         ref="region"
                         v-model="classifyValue"
                         :options="[{id:'',name:'全部分类'}].concat(classifyList)"
                         :props="{checkStrictly: true,value:'id',label:'name'}"></el-cascader>
          </el-form-item> -->

          <el-form-item>
            <el-button type="primary" round @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </template>

      <template v-slot:table="row">
        <el-table
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column
            prop="id"
            label="商品编码"
            width="180">
          </el-table-column>
          <el-table-column
            label="封面图"
            width="100">
            <template slot-scope="scope">
              <img class="goods-cover" :src="scope.row.cover">
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="商品名称"
            width="160">
          </el-table-column>
          <el-table-column
            prop="cateDetails"
            label="分类"
            width="180">
          </el-table-column>
          <el-table-column
            prop="brandName"
            label="品牌"
            width="100">
          </el-table-column>
          <el-table-column
            label="状态"
            width="100">
            <template slot-scope="scope">
              {{saleStatus[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="售价"
            width="80">
          </el-table-column>
          <el-table-column
            prop="purchasePrice"
            label="供货价 "
            width="80"
            v-if="showPoint">
          </el-table-column>
          <el-table-column
            prop="cost"
            label="扣点金额"
            width="100"
            v-if="showPoint">
          </el-table-column>
          <el-table-column
            prop="updateTime_text"
            label="更新时间"
            width="100">
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="100px">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-view"  @click="getGoodsInfo(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>

    <look-goods-details :data="editGoodsData"
                        :visible.sync="visibleAddNew"></look-goods-details>
  </div>
</template>

<script>
  import {baseURL,URL,URL_MINOR} from '../../../config'
  import {isEmpty,objectRemoveEmpty,disposeSearchData,parseTimeArray} from '../../../assets/js/utils'
  import {disposeTreeData} from '../../../assets/js/goodsClassify'
  import lookGoodsDetails from '../../../components/public/lookGoodsDetails/lookGoodsDetails'
  import {goodsReviewStatusArray,saleStatus,saleStatusArray} from '../../../assets/js/status'
  import {mapState} from 'vuex'

  export default {
    name: 'supplierDeletedGoodsList',
    components: {
      lookGoodsDetails
    },
    data(){
      return {
        loading: false,
        /* 数据表格 */
        saleStatus,
        saleStatusArray,
        goodsReviewStatusArray:goodsReviewStatusArray.filter(res=>[1].includes(res.id)),
        searchKeyType:[
          {id: 'goodsId', name: '商品编码'},
          {id: 'goodsName', name: '商品名称'},
          // {id: 'brandName', name: '品牌名称'},
        ],
        classifyList:[],
        classifyValue:[''],
        searchData:{
          searchType:'goodsName',
          searchValue:'',
          firstCateId:'',// 一级分类ID
          secCateId:'',// 2级分类ID
          thirdCateId:'',// 3级分类ID
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10, // 每页显示得条数

        /* 新增属性组弹窗 */
        visibleAddNew:false,
        editGoodsData:{},
        editGoodsType:1,
      }
    },
    created(){
      this.getListClassify()
      this.getList()
    },
    methods:{
      /**
       * 拼接搜索参数
       */
      jointSearchData(type){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data)
        return data;
      },
      /**
       * 搜索
       */
      search(type){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierGoods.deletedList,{params:data}).then(res=>{
            parseTimeArray(res.data.records)
            this.totalListNum = Number(res.data.total);
            this.tableData = res.data.records;
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },
      /**
       * 获取商品信息
       */
      getGoodsInfo(row){
        let data = {
          goodsId:row.id,
        }
        this.loading = true;
        this.axios.get(URL.supplierGoods.goodsInfo,{params:data}).then(res=>{
          res.data.isOfficial = 1 // 正式的
          this.showAddNew(res.data,0)
        }).catch(res=>{

        }).finally(res=>{
          this.loading = false;
        })
      },
      /**
       * 获取分类列表
       */
      getListClassify(){
        let data = {
          status:1,
        };
        this.loading = true;
        this.axios.get(URL.supplierGoods.getSupplierBrandCateList,{params:data}).then(res=>{
          this.classifyList = disposeTreeData(res.data);
        }).catch(res=>{
          this.classifyList = []
          // return Promise.reject(res);
        }).finally(res=>{
          this.loading = false;
        })
      },
      /**
       * 显示 编辑属性组弹窗
       * @param data  {object}  //编辑时需要传入编辑的数据对象
       * @param type  {number}  //编辑类型
       * */
      showAddNew(data,type = 1){
        if(typeof data === 'object' && data.submitParamData){
          this.editGoodsData = data.submitParamData
        }else if(typeof data === 'object' && data.baseGoodsInfo){
          this.editGoodsData = data
        }
        this.editGoodsType = type
        this.visibleAddNew = true
      },
      hideAddNew(){
        if(!isEmpty(this.editGoodsData)) this.editGoodsData = {}
        if(this.$refs.drawerNameEditGoods) this.$refs.drawerNameEditGoods.closeDrawer()
        if(this.visibleAddNew) this.visibleAddNew = false;
      },
      editGoodsSuccess(res){
        this.getList()
        this.hideAddNew()
      },
    },
    computed:{
      ...mapState({
        showPoint:state=>state.userInfo.showCostAndPurchase,
      }),
    },
    watch:{
      classifyValue:function(newValue){
        let [firstCateId,secCateId,thirdCateId] = newValue
        this.searchData.firstCateId = firstCateId
        this.searchData.secCateId = secCateId
        this.searchData.thirdCateId = thirdCateId
        this.search()
      },
    }
  }
</script>

<style lang="less">
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
</style>
<style lang="less" scoped>
  .goods-cover{
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
</style>
